import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { LoadingPage, PageContainer, Seo, Text } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import LoadingBar from './components/LoadingBar';
import Checklist from './components/Checklist';
import Ring from './components/Ring';
import { normalizeStates } from 'utils/localization';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0 1rem;
`;

const ImageContainer = styled.div`
  max-width: 16rem;
`;
const Image = styled.img`
  width: 100%;
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
`;

const Analyzing = styled(Text).attrs({
  type: 'body',
})`
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  transition: opacity ease-in-out 2s;
  opacity: 1;

  color: #3fa134;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 1.95rem */
`;

const Calculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);

  const data = useQuizData('calculating');
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );
  const { goToResults, goToEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: code,
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    email: user?.email,
  });

  const handleNavigationOnCounterEnd = () => {
    if (variant === 'funnel-email' || variant === 'email-first') {
      return location.search.includes('tst3') ? goToResults() : goToEmail();
    }
    goToResults();
  };

  if (!data) {
    return <LoadingPage />;
  }
  const {
    title,
    progressTitle,
    imageUrl,
    list,
    modalQuestion,
    modalAnswer1,
    modalAnswer2,
    showModal,
  } = data;

  return (
    <>
      <Seo />
      <PageContainer with100vh>
        <Header />
        <ContentContainer>
          <ImageContainer>
            <Image src={imageUrl} />
          </ImageContainer>
          <Ring duration={7000} />
          <Analyzing>Calculating...</Analyzing>
          <Checklist
            list={list}
            onEnd={handleNavigationOnCounterEnd}
            onProgress={progress => setProgress(progress)}
            modalQuestion={modalQuestion}
            modalAnswer1={modalAnswer1}
            modalAnswer2={modalAnswer2}
            showModal={showModal}
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default Calculating;
